/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "O Vendoor possui mais de 120 vagas disponíveis, principalmente em Goiânia e região metropolitana. Se você está procurando uma nova oportunidade de emprego, não pode deixar de conferir as excelentes vagas que selecionamos para você esta semana. Veja abaixo algumas das principais oportunidades e como se inscrever."), "\n", React.createElement(_components.p, null, "Vagas de emprego na cidade de Goiânia e Aparecida de Goiânia"), "\n", React.createElement(_components.h2, null, "Estagiário de Direito"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Loteamento. Expansul em Aparecida de Goiânia\n", React.createElement(_components.strong, null, "Salário:"), " R$ 850,00 (fixo)\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Realizar atividades jurídicas e de consultoria, direção jurídica e assessoria, leitura e análise de documentos e processos, elaboração de peças e interlocutória, elaboração de relatórios, pesquisas de jurisprudências, doutrina e legislação, controle de arquivos, digitalização e cópias, protocolo de petições e acompanhamento do Projudi."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Requisitos:"), " Cursando Direito à partir do 7° período, residir ou ter fácil acesso ao Loteamento Expansul em Aparecida de Goiânia\n", React.createElement(_components.strong, null, "Benefícios:"), " Vale-transporte"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/estagiarioa-direito-stival-advogados/"
  }, "Clique aqui"), " para se candidatar."), "\n", React.createElement(_components.h2, null, "Analista Financeiro"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Jardim Novo Mundo em Goiânia, Goiás\n", React.createElement(_components.strong, null, "Salário:"), " De R$ 2.500 até R$ 3.000\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Responsável por contas a receber: verificar os vencidos do dia, acessar o portal para conferir se estão lançados corretamente, manter o controle em planilha, realizar cobranças por e-mail e telefone, tratar problemas diversos pertinentes ao departamento, efetuar cobrança de pagamento via PIX e realizar protesto de boleto quando necessário.\n", React.createElement(_components.strong, null, "Requisitos:"), " Ensino superior completo ou cursando na área de Ciências Contábeis, com experiência mínima de 1 ano na função e experiência com contas a receber.\n", React.createElement(_components.strong, null, "Benefícios:"), " Oferecemos vale alimentação, vale transporte e plano odontológico."), "\n", React.createElement(_components.p, null, "Candidate-se agora, ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/analista-financeiro-maranata-logistica-e-servicos-ltda/"
  }, "clique aqui.")), "\n", React.createElement(_components.h2, null, "Consultor de Vendas - Planos de Saúde"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Vila Brasília em Aparecida de Goiânia, Goiás\n", React.createElement(_components.strong, null, "Salário:"), " De R$ 1.500 até R$ 4.000 (variável)\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " O candidato será responsável por vender planos de saúde, seguros de vida e benefícios. Suas atividades incluem responder mensagens de interesse em prospecções ativas de clientes, receber ligações de clientes que já estão em busca de algum produto, fazer ligações diárias com interessados em produtos de alto nível e guiar os clientes em um pós-venda direcionado.\n", React.createElement(_components.strong, null, "Benefícios:"), " Oferecemos vale alimentação, plano odontológico, vale transporte, seguro de vida, plano de saúde, Gympass, vale combustível e variável em vendas."), "\n", React.createElement(_components.p, null, "Candidate-se agora, ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/consultor-de-vendas-planos-de-saude-benv-beneficios/"
  }, "clique aqui.")), "\n", React.createElement(_components.h2, null, "Atendente"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Local:"), " Setor Marista em Goiânia, Goiás\n", React.createElement(_components.strong, null, "Salário:"), " R$ 1.550\n", React.createElement(_components.strong, null, "Descrição da vaga:"), " Atendimento ao público.\n", React.createElement(_components.strong, null, "Requisitos:"), " Estamos procurando um(a) atendente com boa comunicação, ágil e compromissado. A escala de trabalho é 12/36, das 10:00 às 22:00. É necessário ter ensino médio completo e ser maior de 18 anos.\n", React.createElement(_components.strong, null, "Benefícios:"), " Oferecemos vale transporte e 10% de assiduidade."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/vagas/atendente-ny-cakes-79/"
  }, "Clique aqui"), " para se candidatar à vaga."), "\n", React.createElement(_components.h2, null, "Como se Inscrever"), "\n", React.createElement(_components.p, null, "Para se inscrever em qualquer uma dessas oportunidades, basta acessar o site do ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/"
  }, "Vendoor"), ", procurar as vagas de seu interesse e se candidatar diretamente pela plataforma. Além disso, nosso time de recrutadores está sempre disponível para oferecer suporte e esclarecer quaisquer dúvidas que você possa ter durante o processo. Aproveite essa chance para dar um novo rumo à sua carreira."), "\n", React.createElement(_components.p, null, "Boa sorte!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
